.mainErrorComponent {
    &_main {
        position: absolute;
        width: 100vw;
        height: 100%;
        padding: 2% 25%;
        background: #000;
        color: #fff;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: auto;

        &>img {
            align-self: center;
            margin-bottom: 1%;
        }
    }

    &_header {
        font-size: 26px;
        text-align: center;
        margin-bottom: 2%;
        width: 100%;
        color: #59DA28;
    }

    &_line {
        padding: 0.5%;
        border: 2px solid #ffffff70;
        border-radius: 5px;
        margin-bottom: 1%;
    }

    &_secondLine {
        max-width: 96%;
        color: #59DA28;
        border: 2px solid #5ada2870;
        align-self: flex-end;

        & span {
            margin-right: 12px;
        }
    }

    &_fullLine {
        max-height: 70%;
        min-height: 60px;
        overflow-x: hidden;
    }

    &_mineLine {
        margin-bottom: 0.5%;
    }

    &_button {
        background-color: #000;
        color: #FFF;
        cursor: pointer;
        padding: 6px 24px;
        border: 2px solid #FFF;
        border-radius: 5px;
    }

    &_link {
        background-color: #000;
        color: #59DA28;
        cursor: pointer;
        padding: 6px 24px;
        text-decoration: underline;
    }

    &_form {
        width: 96%;

        &>textarea {
            width: 100%;
            min-height: 150px;
            font-size: 16px;
            padding: 0.5%;
            border: 2px solid #ffffff70;
            border-radius: 5px;
            margin-bottom: 1%;
            background: #000;
            color: #fff;
            transition: 1s ease-out;

            &:disabled {
                color: #ffffff70;
            }
        }
    }
}
.color{
    &_red{
        color: red;
    }
    &_white{
        color: white;
    }
}
