.table {
  border-collapse: collapse;
  padding-bottom: 12px;
  width: 100%;
}

.header {
  top: 0;
  color: #939393;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  position: sticky;
  background-color: #fff;
  border-bottom: 2px solid #0000001A;
  z-index: 200;
}

.header-cell {
  text-align: left;
  padding: 4px 12px 4px 4px;
}

.first-cell {
  padding: 18px 12px 18px 22px !important;
}

.row {
  border-bottom: 1px solid #D9D9D9;
}

.cell {
  text-align: left;
  padding: 18px 12px 18px 4px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin: 16px 0;
}

.button {
  width: inherit;
  padding: 10px 12px;
}
