.main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
}

.centralRow {
    display: flex;
    align-items: center;
    gap: 5px;
}

.hover{
    
}

.main svg{
    border: solid 1px #999;
    border-radius: 2px;
    cursor: pointer;
    transition: .2s ease-out;
    color: #333;
}
.main.hover svg:hover{
    color: #fff;
    border-color: #59DA28;
    background: #59DA28;
}
.main.hover svg:active{
    color: #59DA28;
    border-color: #fff;
    background: #fff;
}