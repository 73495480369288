.container {
  display: flex;
  align-items: center;
}

.switcher-label {
  margin-left: 8px;
  cursor: pointer;
}

.switcher {
  position: relative; width: 42px;
  -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}

.checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 22px;
  padding: 0;
  line-height: 22px;
  border: 2px solid #AEAEAE;
  border-radius: 18px;
  background-color: #AEAEAE;
  transition: background-color 0.3s ease-in, border 0.3s ease-in;
}

.label:before {
  content: "";
  display: block;
  width: 18px;
  margin: 0;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  border: 2px solid #AEAEAE;
  border-radius: 18px;
  transition: all 0.3s ease-in 0s;
}

.checkbox:checked + .label {
  background-color: #49E845;
}

.checkbox:checked + .label, .checkbox:checked + .label:before {
  border-color: #49E845;
}

.checkbox:checked + .label:before {
  right: 0;
}