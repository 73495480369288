.text-button {
  font-weight: bold;
  color: #59DA28;
  text-decoration: underline;
  cursor: pointer;
}

.text-button:hover {
  color: #48b021;
}

.contain-button {
  background-color: #59DA28;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
}

.base {
  padding: 18px 32px;
}

.medium {
  padding: 12px 24px;
}

.contain-button:hover {
  background-color: #48b021;
}

button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  transition: .3s;
}
