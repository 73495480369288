.info-preview {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.info-preview__blank {
  width: 64px;
  height: 64px;
  border-radius: 6px;
  background-color: #d9d9d9;
}

.info-preview__img {
  width: 64px;
  height: 64px;
  border-radius: 6px;
  object-fit: cover;
  cursor: pointer;
}

.info-preview__text {
  margin-left: 1em;
}

.info-preview__text-title {
  font-size: 16px;
  margin: 0 0 0.5em;
}

.info-preview__text-comment {
  font-size: 14px;
  margin: 0;
}

.info-preview__text-header {
  font-size: 16px;
  margin-top: 1em;
}

.info-preview__text-list {
  margin: 0.5em 0;
  font-size: 14px;
  padding-left: 30px;
}
