.connectionMenu {
    &_string {
        font-family: 'Roboto', sans-serif;
        pointer-events: all;
        font-style: normal;
        font-weight: normal;
        list-style: none;
        box-sizing: border-box;
        user-select: none;
        font-size: 18px;
        line-height: 38px;
        transition: .2s ease-out;
        text-decoration: none;
        cursor: pointer;
        color: #cecece;

        &__connection {
            color: #e5c100;
        }

        &__connected {
            color: #59DA28;
        }

        &__error {
            color: red;
        }

        &:hover {
            color: #59DA28;
        }
    }

}