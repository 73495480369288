.modal {
  position: fixed;
  background-color: rgba(72, 72, 72, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  display: flex;
  flex-direction: column;
  background: #FFF;
  border-radius: 10px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #E5E5E5;
}

.modalContent {
  width: 100%;
  padding: 20px;
}
