.input {
  border: 1px solid #D7D7D7;
  border-radius: 6px;
  padding: 10px 12px 10px 40px;
  width: 100%;
}

.text-field {
  padding: 10px 12px !important;
}

.container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.left-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.right-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #a0a0a0;
}

.label {}

.input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.input[type="search"]::-webkit-search-decoration,
.input[type="search"]::-webkit-search-cancel-button,
.input[type="search"]::-webkit-search-results-button,
.input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.input[type="number"]::-webkit-inner-spin-button,
.input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type="number"] {
  -moz-appearance: textfield;
}