.title {
  margin-bottom: 15px;
}

.container {
  margin-bottom: 10px;
}

.label {
  color: #e0e0e0;
  margin-bottom: 8px;
  margin-left: 2px;
}
