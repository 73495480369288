.container {
  display: flex;
  align-items: center;
}

.input {
  margin-right: 0.5rem;
  display: none;
}

.label {
  user-select: none;
  cursor: pointer;
  position: relative;
}

.input + .label::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  margin-right: 0.5rem;
  background-color: white;
  vertical-align: middle;
  transition: background-color .2s;
}

.input:checked + .label::before {
  background-color: #7EDE44;
  border: 1px solid #7EDE44;
}

.label svg {
  position: absolute;
  left: 3.5px;
  top: 5px;
  width: 13px;
  height: 10px;
}