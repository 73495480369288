.settings {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.buttons {
  display: flex;
  gap: 25px;
  margin-top: 15px;
}

.algorithm {
  margin-bottom: 10px;
}

.title {
  margin-bottom: 5px;
}
