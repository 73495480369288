.info {
  display: flex;
  padding: 12px 24px;
  margin-top: 12px;
  background-color: #F8F8F8;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #D9D9D9;
  //margin: 1em 0;
}

.table-filter {
  display: flex;
  margin-bottom: 18px;
  gap: 10px;
}

.table-search {
  height: 80px;
  margin-bottom: 28px;
  margin-left: 40px;
  margin-right: 40px;
}

.table {
  height: calc(90% - 190px);
  overflow-y: scroll;
}

.table-info {
  height: calc(90% - 30px);
  overflow-y: scroll;
}

.table-summary {
  font-weight: 400;
  font-size: 20px;
  margin: 0;
}

.info-summary {
  margin-left: 38px;
}

.info-square {
  margin-bottom: 8px;
}

.info-cost {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

.summary {
  height: 80px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  bottom: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0px -2px 4px 0px #0000001A;
}

.summary-cost {
  font-size: 24px;
  font-weight: 500;
  line-height: 1px;
}

.summary-title {
  font-size: 14px;
}

.blank-text {
  color: #939393;
}

.estimate-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #383C45CC;
  backdrop-filter: blur(10px);
}

.estimate-result {
  position: absolute;
  top: 50%;
  width: min(100%, 1200px);
  height: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFF;
  border-radius: 16px;
  overflow-y: auto;
  padding: 12px 24px;
  z-index: 5000;
  border: none;
}

.estimate-result::-webkit-scrollbar-track {
  margin: 16px 0;
}

.estimate-result__title {
  font-size: 32px;
  padding: 18px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.estimate-result__title-close {
  cursor: pointer;
}

.estimate-result__room {
  background-color: #F5F5F5;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  margin: 18px 0;
}

.estimate-result__room-name {
  font-size: 22px;
}

.estimate-result__room-amount {
  font-size: 24px;
}

.estimate-result__table-title {
  font-size: 18px;
  margin: 24px 4px;
}

.estimate-result__table-amount {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
  border-bottom: 1px solid #D9D9D9;
}

.estimate-result__summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.estimate-filter {
  display: flex;
}

.estimate-filter__tab {
  margin-right: 40px;
  padding-bottom: 10px;
  cursor: pointer;
}

.tab-active {
  font-weight: bold;
  color: #59DA28;
  border-bottom: 2px solid #59DA28;
}

.switch {
  margin-left: auto;
  align-items: center;
  height: 20px;
}

.switch span {
  color: #000 !important;
}

.formatter-window {
  background: #FFF;
  position: absolute;
  width: 380px;
  bottom: 20px;
  left: calc(50% - 190px);
  padding: 10px 14px;
  outline: 1px solid #48b021;
  border-radius: 4px;
}

.formatter-window p {
  margin: 6px 0 16px;
}

@media (max-width: 767px) {
  .table-search {
    margin-left: 20px;
    margin-right: 20px;
  }
  .formatter-window {
    width: calc(100% - 24px);
    margin: 0 12px;
    left: 0;
  }
}
