.container {
  position: fixed;
  overflow: hidden;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.65);
  z-index: 1000000;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.img {
  position: relative;
  max-width: min(1200px, 90%);
  height: 800px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  cursor: auto;
}

.close {
  position: absolute;
  background: #FFF;
  width: 50px;
  height: 50px;
  padding: 10px;
  right: 0;
  top: 0;
}
